<template>
  <div style="background: #fff;">
    <div class="extra-input" style="padding: 16px 16px 0; font-weight: bold;">审批单号： {{ tableData[0] &&
      tableData[0].businessNo }}
    </div>
    <xz-table style="padding: 0 16px 16px; " :columns="columns" :data="tableData" :scroll="{ x: 620, y: 540 }" :rowKey="'id'">
    </xz-table>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import { auditPageList } from '@/api/contract';
import * as Moment from 'dayjs';

export default {
  name: 'AuditList',
  components: {
    xzTable,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
  },
  data () {
    return {
      columns: [
        {
          title: '操作人',
          dataIndex: 'userRealName',
          key: 'createUserName',
          width: 100,
        },
        {
          title: '操作时间',
          dataIndex: 'auditTime',
          key: 'auditTime',
          width: 160,
          customRender: (text) => {
            return text ? Moment(text * 1).format('YYYY-MM-DD HH:mm:ss') : '/';
          },
        },
        {
          title: '操作内容',
          dataIndex: 'auditResult',
          key: 'auditResult',
          width: 160,
        },
        {
          title: '审批意见',
          dataIndex: 'auditDescription',
          key: 'auditDescription',
          width: 200,
        },
      ],
      tableData: [],
    };
  },
  methods: {
    async loadData () {
      const { body } = await auditPageList({
        enablePage: false,
        applyId: this.id,
      });

      this.tableData = body?.list || [];
    },
  },
  mounted () {
    this.loadData();
  },
};
</script>

<style scoped lang="scss">
.extra-input {
  display: flex;
  align-items: flex-start;
  padding: 24px 0 12px 0;
  background: #fff;

  span {
    width: 9em;
    flex-shrink: 0;
  }
}
</style>

