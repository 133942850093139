<template>
  <div class="log-wrapper">
    <a-button v-if="this.isAction" type="primary" style="margin-bottom: 12px;" @click="handleAdd">
      添加事项
    </a-button>
    <a-table ref="logTableRef" :scroll="{ x: '100%', y: 540 }" :components="components" :data-source="tableData" :columns="columns" :pagination="false"
      :rowKey="'id'">
      <span slot="action" slot-scope="text, record">
        <a @click="remind(record)">提醒</a>
      </span>
    </a-table>
    <a-modal v-model="visible" :title="isAddLog ? '添加事项' : '提醒'" :width="600" :bodyStyle="{ padding: '0 12px' }" centered
      wrapClassName="logModal" @cancel="visible = false;" :maskClosable="false">
      <br />
      <template v-if="!isAddLog">
        <a-form-model ref="logForm" :model="form" :rules="rules">
          <a-form-model-item prop="remark">
            <a-textarea v-model="form.remark" placeholder="请输入" :auto-size="{ minRows: 3, maxRows: 5 }" />
          </a-form-model-item>
        </a-form-model>
        <template slot="footer">
          <div>
            <div>
              <a-date-picker v-model="form.remindTime" :disabled-date="disabledDate" :show-time="showTime"
                placeholder="请选择提醒时间" format="YYYY-MM-DD HH:mm" valueFormat="YYYY-MM-DD HH:mm">
                <div>
                  <div :style="iconWrapper"><a-icon type="clock-circle" style="fontSize: 18px;" />
                  </div>
                  <span v-if="form.remindTime" style="margin: 0 0.5em;">{{ form.remindTime }}</span>
                  <span v-else-if="isRemindTimeWarning" style="margin: 0 0.5em; color: #ff0000;">请选择时间</span>
                  <span v-else style="margin: 0 0.5em;"></span>
                </div>
              </a-date-picker>
              <div :style="iconWrapper">
                <a-icon type="user-add" style="fontSize: 18px;" @click="handleReminder" />
              </div>
              <span v-if="form.remindUserList.length">{{ this.form.remindUserList.length === 1 ?
                this.form.remindUserList[0].remindUserName :
                this.form.remindUserList.length > 1 ? `${this.form.remindUserList.length}人` : '' }}</span>
              <span v-else-if="isReminderWarning" style="color: #ff0000;">请选择提醒人</span>
            </div>
          </div>
          <a-button key="submit" type="primary" @click="handleOk">
            添加
          </a-button>
        </template>
      </template>
      <template v-else>
        <a-form-model ref="addLogForm" :model="addLogForm" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="事项类型" prop="businessType">
            <a-select v-model="addLogForm.businessType" placeholder="请选择事项类型">
              <a-select-option v-for="opt in businessTypeOptions" :value="opt.value" :key="opt.value">
                {{ opt.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="事项描述" prop="content">
            <a-textarea v-model="addLogForm.content" placeholder="内容最大长度300" :auto-size="{ minRows: 3, maxRows: 6 }"
              :maxLength="300" />
            <div style="position: absolute; text-align: right;text-align: right;right: 0;top: 1em;">{{
              addLogForm.content.length }}/300</div>
          </a-form-model-item>
        </a-form-model>
        <template slot="footer">
          <div></div>
          <div>
            <a-button @click="visible = false;">
              取消
            </a-button>
            <a-button key="submit" type="primary" @click="handleAddLog">
              保存
            </a-button>
          </div>
        </template>
      </template>
    </a-modal>
    <a-modal v-model="reminderVisible" title="提醒人" :width="600" :bodyStyle="{ padding: '0' }" centered
      wrapClassName="reminderModal" @cancel="reminderVisible = false;" :maskClosable="false">
      <div class="tree-wrapper">
        <div style="flex: 1;">
          <div class="header">
            <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
              全选
            </a-checkbox>
            <span class="statistics">{{ checkedKeys.length }}/{{ allEmployeeKeys.length }}</span>
          </div>
          <a-input-search style="padding: 6px;" placeholder="搜索" @change="onChange" />
          <a-tree v-model="checkedKeys" :expanded-keys="expandedKeys" :auto-expand-parent="autoExpandParent"
            :tree-data="treeDatas" :replaceFields="replaceFields" checkable @check="handleTreeCheck" @expand="onExpand">
            <template slot="name" slot-scope="{ name }">
              <span v-if="name.indexOf(searchValue) > -1">
                {{ name.substr(0, name.indexOf(searchValue)) }}
                <span style="color: #f50">{{ searchValue }}</span>
                {{ name.substr(name.indexOf(searchValue) + searchValue.length) }}
              </span>
              <span v-else>{{ name }}</span>
            </template>
          </a-tree>
        </div>
        <div style="flex: 1; border-left: 1px solid #DFE2E8;">
          <div class="header">已选择</div>
          <div v-for="(e, index) in selectedEmployees" class="employeeItem" :key="e.id">{{ e.name }}<a-icon
              class="li-close-icon" type="close-circle" @click="handleDelete(e, index)" /></div>
        </div>
      </div>
      <template slot="footer">
        <div></div>
        <div>
          <a-button @click="reminderVisible = false;">
            取消
          </a-button>
          <a-button key="submit" type="primary" @click="handleAddReminder">
            确定
          </a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import * as Moment from 'dayjs';
import { saleLogList, purchaseLogList, addSaleLog, addPurchaseLog, addMessageRemind } from '@/api/contract';
import SystemSetting from '@/api/systemSetting';
import DraggableResizeMixin from '@/mixins/draggableResizeMixin.js';

export default {
  name: 'LOG',
  props: {
    id: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'sale',
    },
    isAction: {
      type: Boolean,
      default: true,
    },
    remindType: {
      type: Number,
      default: undefined,
    },
  },
  mixins: [DraggableResizeMixin],
  computed: {
    columns () {
      let ret = [];

      if (this.type === 'sale') {
        ret = this.isAction ? [
          {
            title: '时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 200,
            ellipsis: true,
            customRender: (text) => {
              return text ? Moment(text * 1).format('YYYY-MM-DD HH:mm:ss') : '/';
            },
          },
          {
            title: '操作人',
            dataIndex: 'createUserName',
            key: 'createUserName',
            width: 160,
            ellipsis: true,
          },
          {
            title: '事项类型',
            dataIndex: 'businessTypeName',
            key: 'businessTypeName',
            width: 160,
            ellipsis: true,
          },
          {
            title: '事项描述',
            dataIndex: 'content',
            key: 'content',
            width: 300,
            ellipsis: true,
          },
          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 100,
            fixed: 'right',
            ellipsis: true,
            scopedSlots: { customRender: 'action' },
          },
        ] : [
          {
            title: '时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 200,
            ellipsis: true,
            customRender: (text) => {
              return text ? Moment(text * 1).format('YYYY-MM-DD HH:mm:ss') : '/';
            },
          },
          {
            title: '操作人',
            dataIndex: 'createUserName',
            key: 'createUserName',
            width: 160,
            ellipsis: true,
          },
          {
            title: '事项类型',
            dataIndex: 'businessTypeName',
            key: 'businessTypeName',
            width: 160,
            ellipsis: true,
          },
          {
            title: '事项描述',
            dataIndex: 'content',
            key: 'content',
            ellipsis: true,
            width: 300,
          },

        ];
      } else {
        ret = this.isAction ? [
          {
            title: '时间',
            dataIndex: 'time',
            key: 'time',
            width: 200,
            ellipsis: true,
            customRender: (text) => {
              return text ? Moment(text * 1).format('YYYY-MM-DD HH:mm:ss') : '/';
            },
          },
          {
            title: '操作人',
            dataIndex: 'userName',
            key: 'userName',
            width: 160,
            ellipsis: true,
          },
          {
            title: '事项类型',
            dataIndex: 'type',
            key: 'type',
            width: 160,
            ellipsis: true,
          },
          {
            title: '事项描述',
            dataIndex: 'content',
            key: 'content',
            ellipsis: true,
            width: 300,
          },
          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 100,
            fixed: 'right',
            scopedSlots: { customRender: 'action' },
          },
        ] : [
          {
            title: '时间',
            dataIndex: 'time',
            key: 'time',
            width: 200,
            ellipsis: true,
            customRender: (text) => {
              return text ? Moment(text * 1).format('YYYY-MM-DD HH:mm:ss') : '/';
            },
          },
          {
            title: '操作人',
            dataIndex: 'userName',
            key: 'userName',
            width: 160,
            ellipsis: true,
          },
          {
            title: '事项类型',
            dataIndex: 'type',
            key: 'type',
            width: 160,
            ellipsis: true,
          },
          {
            title: '事项描述',
            dataIndex: 'content',
            key: 'content',
            ellipsis: true,
            width: 300,
          },
        ];
      }

      return ret;
    },
  },
  data () {
    return {
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      isRemind: false,
      visible: false,
      form: {
        remark: '',
        remindTime: '',
        remindUserList: [],
      },
      rules: {
        businessType: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        remark: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
      iconWrapper: {
        display: 'inline-block',
        background: '#F2F4F7',
        width: '32px',
        height: '32px',
        textAlign: 'center',
        padding: '7px 0',
      },
      reminderVisible: false,
      treeDatas: [],
      replaceFields: {
        children: 'children',
        title: 'name',
        key: 'id',
      },
      isAddLog: false,
      addLogForm: {
        businessId: '',
        businessType: '',
        content: '',
        module: 'SCM',
        source: 'MANUAL',
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      businessTypeOptions: [
        {
          label: '发货跟进',
          value: 'DELIVERY_FOLLOW',
        },
        {
          label: '付款跟进',
          value: 'PAYMENT_FOLLOW',
        },
        {
          label: '发票跟进',
          value: 'INVOICE_FOLLOW',
        },
        {
          label: '其他',
          value: 'OTHER',
        },
      ],
      businessTypeMap: {
        DELIVERY_FOLLOW: '发货跟进',
        PAYMENT_FOLLOW: '付款跟进',
        INVOICE_FOLLOW: '发票跟进',
        OTHER: '其他',
      },
      checkedKeys: [],
      selectedEmployees: [],
      treeDataMap: {},
      indeterminate: true,
      checkAll: false,
      allEmployeeKeys: [],
      searchValue: '',
      expandedKeys: [],
      autoExpandParent: true,
      dataList: [],
      isRemindTimeWarning: false,
      isReminderWarning: false,
      showTime: {
        format: 'HH:mm',
        disabledHours: this.disabledHours,
        disabledMinutes: this.disabledMinutes,
      },
      maxLength: 300,
    };
  },
  methods: {
    async loadData () {
      const api = this.type === 'sale' ? saleLogList : purchaseLogList;

      const { body } = await api({
        businessId: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        relationId: this.id,
        logTypeList: this.type === 'purchOrder' ? [3] : [2],
      });

      this.tableData = [...this.tableData, ...(body?.list || [])];

      this.total = body.total;
    },
    tableScroll () {
      let target = this.$refs.logTableRef.$el.querySelector('.ant-table-body');

      const scrollHeight = target.scrollHeight - target.scrollTop;
      const clientHeight = target.clientHeight;
      if (scrollHeight === 0 && clientHeight === 0) {
        this.pageNum = 1;
      } else {
        if (scrollHeight < clientHeight + 5) {
          if (this.tableData.length < this.total) {
            this.pageNum += 1;

            this.loadData();
          }
        }
      }
    },
    range (start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledDate (current) {
      return Moment(current).unix() < Moment().subtract(1, 'day').unix();
    },
    disabledHours () {
      const now = Moment();

      const hour = now.hour();

      return this.range(0, hour);
    },
    disabledMinutes (selectedHour) {
      const now = Moment();

      const hour = now.hour();
      const min = now.minute();

      return this.range(0, selectedHour < hour ? 60 : selectedHour === hour ? min : 0);
    },
    onExpand (expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    setRDepartmentsAndEmployeees (vals) {
      vals.forEach(v => {
        (v.employees || []).forEach(v => {
          v.isEmployee = true;

          if (!this.treeDataMap[v.id]) {
            this.treeDataMap[v.id] = v.name;
          }

          if (!this.allEmployeeKeys.includes(v.id)) {
            this.allEmployeeKeys.push(v.id);
          }

          v.scopedSlots = { title: 'name' };
        });

        (v.children || []).forEach(v => {
          v.checkable = false;

          v.scopedSlots = { title: 'name' };
        });

        v.children = [...(v.children || []), ...(v.employees || [])];

        if (v.children.length) {
          this.setRDepartmentsAndEmployeees(v.children);
        }
      });
    },
    async getDepartmentsAndEmployees () {
      const { body } = await SystemSetting.getDepartmentLists({});

      (body || []).forEach(v => {
        v.checkable = false;
        v.scopedSlots = { title: 'name' };
      });

      this.setRDepartmentsAndEmployeees(body || []);

      this.treeDatas = body || [];

      this.generateList(this.treeDatas);
    },
    onCheckAllChange (e) {
      this.indeterminate = false;
      this.checkAll = e.target.checked;

      this.checkedKeys = e.target.checked ? [...this.allEmployeeKeys] : [];

      this.selectedEmployees = [];

      if (e.target.checked) {
        const keys = Object.keys(this.treeDataMap);

        for (const k of keys) {
          this.selectedEmployees.push({
            id: k,
            name: this.treeDataMap[k],
          });
        }
      }
    },
    handleTreeCheck (keys, e) {
      this.selectedEmployees = [];

      this.indeterminate = !!keys.length && keys.length < this.allEmployeeKeys.length;
      this.checkAll = keys.length === this.allEmployeeKeys.length;

      e.checkedNodes.forEach(node => {
        if (node.data.props.isEmployee) {
          this.selectedEmployees.push({
            id: node.data.props.id,
            name: node.data.props.name,
          });
        }
      });
    },
    handleDelete (v, idx) {
      let index = this.checkedKeys.findIndex(key => key === v.id);

      this.selectedEmployees.splice(idx, 1);

      if (index !== -1) {
        this.checkedKeys.splice(index, 1);
      }
    },
    remind (record) {
      this.form.remark = record.content;
      this.form.remindTime = '';
      this.form.remindUserList = [];

      this.currentRecord = record;

      this.isRemindTimeWarning = false;
      this.isReminderWarning = false;
      this.isAddLog = false;
      this.visible = true;
    },
    handleReminder () {
      this.autoExpandParent = true;
      this.searchValue = '';

      this.checkedKeys = this.form.remindUserList.map(user => user.remindUserId);
      this.expandedKeys = [...this.allEmployeeKeys];

      this.reminderVisible = true;
    },
    generateList (data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        this.dataList.push({ id: node.id, name: node.name });
        if (node.children) {
          this.generateList(node.children);
        }
      }
    },
    getParentKey (key, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some(item => item.id === key)) {
            parentKey = node.id;
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children);
          }
        }
      }

      return parentKey;
    },
    onChange (e) {
      const value = e.target.value;

      const expandedKeys = this.dataList
        .map(item => {
          if (item.name.indexOf(value) > -1) {
            return this.getParentKey(item.id, this.treeDatas);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);

      this.searchValue = value;
      this.expandedKeys = expandedKeys;
    },
    handleAddReminder () {
      this.form.remindUserList = this.checkedKeys.map(k => {
        return {
          remindUserId: k,
          remindUserName: this.treeDataMap[k],
        };
      });

      this.reminderVisible = false;
    },
    handleOk () {
      const ret = this.form.remindTime && this.form.remindUserList.length;

      if (!this.form.remindTime) {
        this.isRemindTimeWarning = true;
      }

      if (!this.form.remindUserList.length) {
        this.isReminderWarning = true;
      }

      this.$refs.logForm.validate(async valid => {
        if (valid && ret) {
          await addMessageRemind({
            linkId: this.currentRecord.id,
            objId: this.currentRecord.businessId,
            ...this.form,
            remindType: this.remindType,
            businessTypeName: this.currentRecord.type || this.currentRecord.businessTypeName,
          });

          this.$message.success('提醒成功');

          this.visible = false;
        }
      });
    },
    /* 添加事项 */
    handleAdd () {
      this.isAddLog = true;

      this.addLogForm.businessType = '';
      this.addLogForm.content = '';

      this.visible = true;

      this.$nextTick(() => {
        this.$refs.addLogForm.resetFields();
      });
    },
    /* 保存添加事项 */
    handleAddLog () {
      this.$refs.addLogForm.validate(async valid => {
        if (valid) {
          const api = this.type === 'sale' ? addSaleLog : addPurchaseLog;

          let param = {};

          if (this.type === 'sale') {
            param = {
              ...this.addLogForm,
              businessId: this.id,
            };
          } else {
            param = {
              type: this.businessTypeMap[this.addLogForm.businessType],
              content: this.addLogForm.content,
              logType: this.remindType - 1, // remindType: 3 => 采购合同， 4 => 采购订单; logType: 2 => 采购合同， 3 => 采购订单
              relationId: this.id,
              source: this.addLogForm.source,
            };
          }

          await api(param);

          this.pageNum = 1;
          this.pageSize = 10;

          this.tableData = [];

          this.loadData();

          this.visible = false;
        }
      });
    },
  },
  created () {
    this.getDepartmentsAndEmployees({});
  },
  mounted () {
    this.loadData();

    this.$refs.logTableRef.$el
      .querySelector('.ant-table-body')
      .addEventListener('scroll', this.tableScroll, {
        passive: true,
      });
  },
  beforeDestroy () {
    this.$refs.logTableRef.$el
      .querySelector('.ant-table-body')
      .removeEventListener('scroll', this.tableScroll);
  },
};
</script>
<style lang="scss" scoped>
.log-wrapper {
  background-color: #fff;
  padding: 16px;

  /deep/ .ant-table-thead {
    &>tr>th {
      padding: 9px 16px;
      font-weight: bold;
      background-color: #F2F4F7;
    }
  }

  /deep/ .ant-table-tbody {
    tr {
      background-color: #fff;

      &>td {
        padding: 9px 16px;
      }
    }
  }
}
</style>

<style lang="scss">
.logModal {
  .ant-calendar-picker {
    min-width: 0 !important;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
}

.reminderModal {
  .tree-wrapper {
    display: flex;
    height: 60vh;
    overflow: auto;

    .header {
      position: relative;
      height: 40px;
      line-height: 40px;
      background: #F2F4F7;
      padding: 0 8px;
      border-bottom: 1px solid #DFE2E8;

      .statistics {
        position: absolute;
        right: 10px;
      }
    }

    .employeeItem {
      position: relative;
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      padding: 0 9px;

      .li-close-icon {
        position: absolute;
        right: 9px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        &:hover {
          color: #1890ff;
        }
      }
    }
  }
}
</style>
